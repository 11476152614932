<template>
  <v-dialog :value="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h5"> Create Model </v-card-title>

      <div class="px-5 my-5">
        <v-text-field
          label="Model Name"
          placeholder="Model Name"
          outlined
          v-model="modelName"
        ></v-text-field>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="addModel"> Create </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions: modelsActions } = createNamespacedHelpers('MLModels');

export default {
  name: 'CreateModels',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelName: '',
    };
  },
  methods: {
    ...modelsActions(['createModel']),
    addModel() {
      this.createModel({
        name: this.modelName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './create-model';
</style>
