<template>
  <div class="MLModelsList">
    <v-row class="MLModelsList__filter-row">
      <v-col>
        <v-btn text small color="primary" @click="isDialogOpen = true">
          + Add Model
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="MLModelsList__data-table elevation-0"
      outlined
      item-key="id"
      :item-class="() => 'MLModels__data-table-row'"
      :items="models"
      :headers="headers"
      :loading="fetchModelsLoading || deleteModelLoading"
      @click:row="handleGoToModel"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="error" @click.stop="triggerDelete(item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <action-prompt
      v-model="showDeleteModelModal"
      v-if="showDeleteModelModal"
      action-text="Delete"
      :action="removeModel"
      :loading="deleteModelLoading"
      header="Delete Model"
      :body="deleteMessageBody"
      action-type="error"
    />
      <create-model :dialog="isDialogOpen" @close="isDialogOpen = false" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ActionPrompt from '@/components/modals/action-prompt/action-prompt.vue';
import CreateModel from "@/components/modals/create-model/create-model.vue";

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
    mapGetters: modelsGetters,
    mapActions: modelsActions,
    mapMutations: modelsMutations,
} = createNamespacedHelpers('MLModels');

export default {
  name: 'MLModels',
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      modelsCount: null,
      modelId: null,
      showDeleteModelModal: false,
      isDialogOpen: false,
    };
  },
  components: {
    ActionPrompt,
    CreateModel
  },
    watch: {
        'selectedCustomer.customer_id'() {
            this.fetchModels();
        },
    },
  computed: {
    ...modelsGetters({
        models: 'MODELS',
        deleteModelLoading: 'DELETE_MODEL_LOADING',
        fetchModelsLoading: 'FETCH_MODELS_LOADING',
    }),
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    deleteMessageBody() {
      return 'Are you sure you want to delete this model?';
    },
  },
  mounted() {
      this.fetchModels();
  },
  methods: {
      ...modelsMutations({}),
      ...modelsActions(['fetchModels', 'deleteModel']),
    triggerDelete({ id, count }) {
      this.modelsCount = count;
      this.modelId = id;
      this.showDeleteModelModal = true;
    },
    async removeModel() {
      await this.deleteModel(this.modelId);
      this.workerCount = null;
      this.workerId = null;
    },
    handleGoToModel(item) {
      this.$router.push({ path: `/models/${item.id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './ml-models';
</style>
